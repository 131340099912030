import Generic from "./Generic";
import Global from "./Global";

class Transaction extends Generic {
  static get METHOD_OTHER() { return 0 }
  static get METHOD_INAPP() { return 1 }
  static get METHOD_TRANSFER() { return 2 }
  static get METHOD_CASH() { return 3 }

  /** @param {Transaction} [obj] */
  constructor(obj) {
    super(obj);

    const { amount, blockHash, method, payer, timestamp } = obj ?? {};

    this.setAmount(amount);
    this.setBlockHash(blockHash);
    this.setMethod(method);
    this.setPayer(payer);
    this.setTimestamp(timestamp)
  }

  /** Obtains the transaction's amount. */
  getAmount() {
    return this.amount;
  }

  /** Assings an amount.
   * @param {number} amount 
   */
  setAmount(amount) {
    this.amount = isNaN(amount) || Number(amount) < 0
      ? undefined
      : Number(amount);
  }

  /** Obtains the transaction's payer */
  getPayer() {
    return this.payer;
  }

  /** Obtains the block hash where this transaction is located. */
  getBlockHash() {
    return this.blockHash;
  }

  /** Assings the block hash where this transaction is located.
   * @param {string} [blockHash] 
   */
  setBlockHash(blockHash) {
    this.blockHash = blockHash;
  }

  /** Assings a payer.
   * @param {import('./User').default} payer 
   */
  setPayer(payer) {
    this.payer = payer;
  }

  /** Obtains the pay type.
   * @returns {0|1|2|3}
   */
  getMethod() {
    return this.method;
  }

  /** Assings the pay type
   * @param {0|1|2|3} method 
   */
  setMethod(method) {
    this.method = method >= Transaction.METHOD_OTHER && method <= Transaction.METHOD_CASH
      ? method
      : Transaction.METHOD_OTHER;
  }

  /** Obtains the transaction's timestamp. */
  getTimestamp() {
    return this.timestamp;
  }

  /** Assings a timestamp.
   * @param {Number} timestamp 
   */
  setTimestamp(timestamp) {
    this.timestamp = Global.formatDateUTC(timestamp);
  }
}

export default Transaction;