import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import MyProfile from './MyProfile';
import Home from './Home';
import Navbar from './components/Navbar';
import PageNotFound from './PageNotFound';
import PublEstate from './PublEstate';
import PrivEstate from './PrivEstate';
import Search from './Search';
import Signup from './Signup';
import LicenseManagement from './LicenseManagement';
import StateContext from './context/StateContext';
import Global from './objects/Global';
import AgreEstaViewer from './AgreEstaViewer';
import UserManagement from './UserManagement';
import AgreementView from './AgreementView';
import EmailManagement from './EmailManagement';

const App = () => {
  return (
    <Router>
      <div className="App">
        <StateContext>
          <Navbar />
          <Routes>
            {/* Main Page*/}
            <Route path="" element={<MainPage />} />
            {/* Sign up page */}
            <Route path={Global.PATH_SIGNUP} element={<Signup />} />
            {/* Results page and filters side menu component*/}
            <Route path={Global.PATH_SEARCH} element={<Search key='1' />} />
            {/* Publishment page*/}
            <Route path={`${Global.PATH_PUBLIC_ESTATE}/:idEstate`} element={<PublEstate />} />
            {/* My profile page */}
            <Route path={Global.PATH_ACCOUNT} element={<MyProfile />} />
            {/* Email Management page (verification and restoration) */}
            <Route path={`${Global.PATH_ACCOUNT}/verificar-correo/:emailCode`} element={<EmailManagement mode={0} />} />
            <Route path={`${Global.PATH_ACCOUNT}/restaurar-correo/:emailCode`} element={<EmailManagement mode={1} />} />
            {/* Home (estate and contracts page) */}
            <Route path={Global.PATH_HOME} element={<Home />} />
            {/* See all contracts page */}
            <Route path={Global.PATH_LEASES_VIEW} element={<AgreEstaViewer mode='contracts' />} />
            {/* See all estates page */}
            <Route path={Global.PATH_ESTATES_VIEW} element={<AgreEstaViewer mode='estates' />} />
            {/* Private publisment page */}
            <Route path={`${Global.PATH_ESTATES_VIEW}/:idEstate`} element={<PrivEstate />} />
            {/* Private contract page */}
            <Route path={`${Global.PATH_CONTRACT}/:contract`} element={<AgreementView />} />
            {/* Subscription page */}
            <Route path={Global.PATH_LICENSE_MANAGEMENT} element={<LicenseManagement />} />
            {/* User management */}
            <Route path={Global.PATH_USER_MANAGEMENT} element={<UserManagement />} />
            {/* 404 page */}
            {/* User Management page */}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </StateContext>
      </div>
    </Router>
  );
}

export default App;
