import '../styles/transaction-regist.css';
import { useContext, useEffect, useRef, useState } from 'react';
import * as Icons from '../../assets/images';
import Button from '../Button';
import Inputbar from '../Inputbar';
import Selectbar from '../Selectbar';
import UIRender from '../../objects/UIRender';
import Transaction from '../../objects/Transaction';
import Global from '../../objects/Global';
import { globalContext } from '../../context/GlobalContext';
import User from '../../objects/User';
import Hintbox from '../Hintbox';
import PropDisplay from '../PropDisplay';

/** ValuesObject typedef
 * @typedef {Object} ValuesObject
 * @property {number} method
 * @property {number} payDate
 * @property {string} payer
 * @property {string} reference
 */

/** RegisterTransactionResponse typedef
 * @typedef {Object} RegisterTransactionResponse
 * @property {string} [idpayment] Database id.
 * @property {number} nextDeadline The next deadline.
 * @property {number} timestamp
 */

/** Props typedef
 * @typedef {Object} TransactionRegistPropsObject
 * @property {(t: Transaction, period: number) => Promise<RegisterTransactionResponse>} action A callback
 * function that will be triggered when popup is on action.
 * @property {string} [id] A custom id. 'transaction-regist' is the default.
 * @property {string[]} lessees The lessees' usernames atached to the transaction. Each one must be unique.
 * @property {() => void} onHide
 * @property {(error: *) => void} onReject
 * @property  {(t: Transaction, nextDeadline: number) => void} onResolve
 * @property {number} amount The price to be paid.
 * @property {number} today The current day.
 * @property {import('../TransactionLineTime').TransactionLabelObject} trLabel
 */

/** Renders a TransactionRegist compo.
 * @param {TransactionRegistPropsObject} props The props object.
 */
const TransactionRegist = props => {
  // *** useContext ***
  const { timezoneOffset } = useContext(globalContext);
  // *** useRef ***
  const compoId = useRef(props.id || 'transaction-regist');
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));
  const referenceRollback = useRef(/** @type {(input?: string) => void} */(undefined));
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [values, setValues] = useState(
    /** @type {ValuesObject} */({ payer: props.lessees.length === 1 ? props.lessees[0] : undefined })
  );

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const isPayDateValid = input => {
    if (input === undefined) return false;

    const date = Global.dateToMilliUTC(Global.transformDate(input), -timezoneOffset);

    return date <= props.today;
  }

  const payerSbrChangeHandler = option => {
    values.payer = option;
    requestEnableSubmit();
  }

  const payDateIbrChangeHandler = input => {
    values.payDate = Global.formatDateUTC(input, -timezoneOffset);
    setValues({ ...values });
    requestEnableSubmit();
  }

  const payTypeSbrChangeHandler = option => {
    values.method = option;

    setValues({
      payDate: values.payDate,
      payer: values.payer,
      method: option,
      reference: values.reference
    });

    referenceRollback.current('');
    requestEnableSubmit();
  }

  const referenceIbrChangeHanlder = input => {
    values.reference = input;
    requestEnableSubmit();
  }

  const registerBtnClickHandler = () => {
    const auxTr = new Transaction({});
    auxTr.setAmount(props.amount);
    auxTr.setCreationDate(values.payDate)
    auxTr.setId(values.reference);
    auxTr.setMethod(values.method);
    auxTr.setPayer(new User({ username: values.payer }));
    auxTr.setTimestamp(values.payDate);

    setDisableUI(true);
    props.action(auxTr, props.trLabel.period)
      .then(data => {
        auxTr.setTimestamp(data.timestamp);
        props.onResolve(auxTr, data.nextDeadline);
        UIRender.hideElement(popup.current);
      }).catch(err => {
        setDisableUI(false);
        props.onReject(err);
      });
  }

  const requestEnableSubmit = () => {
    setDisableSubmit(values.payDate === undefined
      || values.method === undefined
      || (values.method === Transaction.METHOD_TRANSFER && values.reference === undefined)
      || values.payer === undefined);
  }

  // Page behavior
  useEffect(() => {
    const id = compoId.current;
    const parent = popup.current?.parentNode;
    const options = { footer: true, navbar: true };

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options);

    return () => {
      // Enable childs.
      UIRender.enableChilds(parent, options, id);
      // Enable global scroll.
      UIRender.enableGlobalScroll(id);
    }
  }, []);

  return (
    <div className='transaction-regist popup-wrapper' id={compoId.current} ref={popup} onAnimationEnd={dispose}>
      <div className='popup' >
        <div className="top-bar">
          <h3 className='highlight'>Registrar pago</h3>
          <Button disabled={disableUI}
            borderless
            empty
            icon={Icons.CloseIcon}
            onClick={() => UIRender.hideElement(popup.current)}
            reduced
            rounded
            typeRender='error' />
        </div>
        <div className='popup-content' >
          <PropDisplay header='Monto a registrar'
            largeFont
            property={`$ ${Global.formatNumber(props.amount)} MXN`} />
          <Selectbar disabled={disableUI}
            icon={Icons.PayIcon}
            onChange={payTypeSbrChangeHandler}
            options={[
              { displayValue: 'Transferencia bancaria', value: Transaction.METHOD_TRANSFER },
              { displayValue: 'Efectivo', value: Transaction.METHOD_CASH },
              { displayValue: 'Otro', value: Transaction.METHOD_OTHER }
            ]} placeholder='Método de pago'
            required />
          <Selectbar defaultValue={props.lessees.length === 1 ? props.lessees[0] : undefined}
            disabled={props.lessees.length === 1 || disableUI}
            onChange={payerSbrChangeHandler}
            options={props.lessees.map(l => { return { displayValue: l, value: l } })}
            placeholder='Responsable'
            required />
          <Inputbar disabled={(values.method !== Transaction.METHOD_TRANSFER
            && values.method !== Transaction.METHOD_OTHER)
            || disableUI}
            filters={[{ regExp: /[^A-Za-z0-9-]/ }]}
            forceChangeRef={referenceRollback}
            maxLength={30}
            onChange={referenceIbrChangeHanlder}
            placeholder={{ default: 'Folio o referencia' }}
            required={values.method === Transaction.METHOD_TRANSFER} />
          <Inputbar disabled={disableUI}
            isValid={isPayDateValid}
            onChange={payDateIbrChangeHandler}
            placeholder={{ default: 'Fecha de operación', onIsValidFail: 'Fecha inválida' }}
            required
            type='date' />
          {values?.payDate !== undefined && <Hintbox icon={Icons.WarningIcon}
            message={values.payDate <= props.trLabel.limitDate
              ? "La transacción será registrada como Pagada a tiempo."
              : "La transacción será registrada como Pago tardío."
            } type={values.payDate <= props.trLabel.limitDate ? 'complete' : 'warning'} />}
          <Button disabled={disableSubmit}
            icon={Icons.PayIcon}
            isWaiting={disableUI}
            onClick={registerBtnClickHandler}
            value='Registrar transacción' />
        </div>
      </div>
    </div>
  );
}

export default TransactionRegist;