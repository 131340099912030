import Generic from "./Generic";

/** Generic MailConfig definition.
 * @typedef {Object} MailConfigObject
 * @property {boolean} agre_canc An agreement has been cancelled.
 * @property {boolean} agre_crea An agreement has been signed. 
 * @property {boolean} agre_dela An agreement payment is delayed.
 * @property {boolean} agre_near An agreement is near to its end or a deadline (lessee only).
 * @property {boolean} agre_paym An agreement has been paid.
 * @property {boolean} cont_requ Someone requested contact info (lessor only).
 * @property {boolean} subs_paym A subscription has been paid or payment was rejected.
 */

/** The Mail push configuration. */
class MailConfig extends Generic {
  /** An agreement has been cancelled. */
  static get AGRE_CANC() { return 'agre_canc' };
  /** An agreement has been signed. */
  static get AGRE_CREA() { return 'agre_crea' };
  /** An agreement payment is delayed. */
  static get AGRE_DELA() { return 'agre_dela' };
  /** An agreement is near to its end or a deadline (lessee only). */
  static get AGRE_NEAR() { return 'agre_near' };
  /** An agreement has been paid */
  static get AGRE_PAYM() { return 'agre_paym' };
  /** Someone requested contact info (lessor only). */
  static get CONT_REQU() { return 'cont_requ' };
  /** A subscription has been paid or paymend was rejected. */
  static get SUBS_PAYM() { return 'subs_paym' };

  /** @param {MailConfig|MailConfigObject} [object] */
  constructor(object) {
    super(object);

    /** @type {MailConfigObject} */
    this.config = {
      agre_canc: Boolean(object?.agre_canc || object?.config?.agre_canc),
      agre_crea: Boolean(object?.agre_crea || object?.config?.agre_crea),
      agre_dela: Boolean(object?.agre_dela || object?.config?.agre_dela),
      agre_near: Boolean(object?.agre_near || object?.config?.agre_near),
      agre_paym: Boolean(object?.agre_paym || object?.config?.agre_paym),
      cont_requ: Boolean(object?.cont_requ || object?.config?.cont_requ),
      subs_paym: Boolean(object?.subs_paym || object?.config?.subs_paym)
    }

    Object.freeze();
  }

  /** Obtains all configs */
  getAllConfigs() {
    return this.config;
  }

  /** Obtains the specified pass.
   * @param {string} attr Config name (defined by class globals)
   * @returns {boolean}
   */
  getConfig(attr) {
    return this.config[`${attr}`];
  }

  /** Assings new value to the specified config.
   * @param {string} attr Config name (defined by class globals) 
   * @param {boolean} val New value for the config.
   */
  setConfig(attr, val) {
    if (this.config[`${attr}`] !== undefined)
      this.config[`${attr}`] = val;
  }
}

export default MailConfig;