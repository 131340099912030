import './styles/search.css';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { globalContext } from "./context/GlobalContext";
import * as Icons from './assets/images';
import Button from './components/Button';
import DAOServ from './objects/DAOServ';
import Dialog from './components/popups/Dialog';
import Global from './objects/Global';
import Hintbox from './components/Hintbox';
import Inputbar from './components/Inputbar';
import LoadingPanel from './components/LoadingPanel';
import NotFoundBox from './components/NotFoundBox';
import PushButton from './components/PushButton';
import ErrHandler from './objects/ErrHandler';
import Estate from './objects/Estate';
import SearchItem from './components/SearchItem';
import GenericFile from './objects/GenericFile';
import User from './objects/User';
import LoadingBlock from './components/LoadingBlock';

/** The filters type definition.
 * @typedef {Object} FiltersObject
 * @property {string} [keywords] The keywords, used for searching in titles and descriptions.
 * @property {{ max: number, min: number }} [priceRange] The price range.
 * @property {number[]} [types] The estate types
 * @property {number[]} [sellMethods] The estate sell methods
 */

/** Renders a Search page compo. */
const Search = () => {
  // *** useContext ***
  const {
    currSession,
    pushMessageHint,
    setSearchMethod,
    setShowFooter,
    setShowLoadingScreen
  } = useContext(globalContext);
  // *** useJsApiLoader
  const gLib = useRef(['places']);
  const { isLoaded: isGAPILoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_G_API,
    libraries: gLib.current
  });
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const filters = useRef(/** @type {FiltersObject} */({})); // The filters ref.
  const filtersPrev = useRef( /** @type {FiltersObject} */({})); // The prev filters ref.
  const map = useRef(/** @type {google.maps.Map} */(undefined));
  const inputLocationRef = useRef(/** @type {HTMLInputElement} */(undefined));
  const inputMaxPriceRollback = useRef(/** @type {(nV?: string) => void} */(undefined));
  const inputMinPriceRollback = useRef(/** @type {(nV?: string) => void} */(undefined));
  const toolsMenuRef = useRef( /** @type {HTMLDivElement} */(undefined));
  // *** useState ***
  const [currEstates, setCurrEstates] = useState(/** @type {Estate[]} */(undefined));
  const [currPos, setCurrPos] = useState(/** @type {{lat: Number, lng: Number}} */(Global.MX_LOCATION));
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(true);
  const [gLocState, setGLocState] = useState(/** @type {-1|0|1} */(undefined));
  const [results, setResults] = useState(/** @type {(Estate|RateObject)[]} */(undefined));
  const [showDialog, setShowDialog] = useState(false);
  const [showToolsMenu, setShowToolsMenu] = useState(false);

  /** The Estate Sell Method button click handler.
   * @param {boolean} state The current state of pushed button.
   * @param {number} sellMethod The Estate.ON value from clicked button.
   */
  const estateSellMethodBtnClickHandler = (state, sellMethod) => {
    if (state) {
      if (!filters.current.sellMethods)
        filters.current.sellMethods = [sellMethod];
      else
        filters.current.sellMethods.push(sellMethod);
    } else {
      if (filters.current.sellMethods.length === 1)
        filters.current.sellMethods = undefined;
      else
        filters.current.sellMethods.splice(filters.current.sellMethods.findIndex(sM => sM === sellMethod), 1);
    }

    requestEnableUpdateBtn()
  }

  /** The Estate Type button click handler.
   * @param {boolean} state The current state of pushed button.
   * @param {number} type The Estate.TYPE value from clicked button.
   */
  const estateTypeBtnClickHandler = (state, type) => {
    if (state) {
      if (!filters.current.types)
        filters.current.types = [type];
      else
        filters.current.types.push(type);
    } else {
      if (filters.current.types.length === 1)
        filters.current.types = undefined;
      else
        filters.current.types.splice(filters.current.types.findIndex(t => t === type), 1);
    }

    requestEnableUpdateBtn();
  }

  /** Obtains the current session's location. */
  const getLocation = useCallback(() => {
    if (!Boolean(localStorage.getItem('locationPass'))) {
      setShowDialog(true);
    } else {
      setGLocState(0);

      navigator.geolocation.getCurrentPosition(position => {
        const { latitude: lat, longitude: lng } = position.coords;

        DAOServ.post('find_address', { geoData: { lat, lng } }, 'JSON')
          .then(query => {
            if (inputLocationRef.current)
              inputLocationRef.current.value = query['formatted_address']

            map.current.setZoom(16);
            setCurrPos({ lat, lng });
          }).catch(err => {
            if (err) pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
          }).finally(() => setGLocState(1));
      }, err => {
        if (Global.DEV_MODE) console.log(err);

        pushMessageHint({ message: 'No se puede obtener tu ubicación', type: 'error' });
        setGLocState(1);
      });
    }
  }, [pushMessageHint]);

  const getToolsClassName = () => {
    let className = 'tools';

    if (showToolsMenu) className += ' show';

    return className;
  }

  const isCurrPosAtOrigin = () => {
    return currPos?.lat === Global.MX_LOCATION.lat
      && currPos.lng === Global.MX_LOCATION.lng;
  }

  const keywordsIbChangeHandler = input => {
    filters.current.keywords = input;
    requestEnableUpdateBtn();
  }

  /** @type {(auxMap: google.maps.Map) => void} */
  const onMapLoad = useCallback(auxMap => {
    auxMap.setZoom(5);
    map.current = auxMap;
  }, []);

  /** The price range change handler
   * @param {'max'|'min'} type 
   * @param {string} [value] 
   */
  const priceRangeIbChangeHandler = (type, value) => {
    if (!value) {
      if (filters.current.priceRange) {
        filters.current.priceRange[type] = undefined;

        if (filters.current.priceRange.max === undefined && filters.current.priceRange.min === undefined)
          filters.current.priceRange = undefined;
      }
    } else {
      if (!filters.current.priceRange)
        filters.current.priceRange = {};

      filters.current.priceRange[type] = Number(value);
      const { max, min } = filters.current.priceRange;

      if (type === 'min' && max !== undefined && filters.current.priceRange.min > max) {
        filters.current.priceRange.max = filters.current.priceRange.min;
        inputMaxPriceRollback.current(value);
      } else if (type === 'max' && min !== undefined && filters.current.priceRange.max < min) {
        filters.current.priceRange.min = filters.current.priceRange.max;
        inputMinPriceRollback.current(value);
      }
    }

    requestEnableUpdateBtn();
  }

  const renderResults = () => {
    const reRend = [];
    /** @type {Estate[]} */
    const auxRes = results;
    auxRes?.forEach(r => {
      const sM = r.getSellMethod();
      reRend.push(<Marker key={`estate-${r.getId()}`}
        icon={{
          url: r.getBidding().getStatus()
            ? Icons.PointBidding
            : sM === Estate.ON_COMPLEX
              ? Icons.PointComplex
              : sM === Estate.ON_LEASE
                ? Icons.PointLease
                : Icons.PointSale,
          scaledSize: { height: 20, width: 20 },
          size: { height: 20, width: 20 }
        }} onClick={() => setCurrEstates([r])}
        position={r.getLocation().getGeoData()} />)
    });

    return reRend;
  }

  const requestEnableUpdateBtn = () => {
    let sameKeywords, samePriceRange, sameTypes, sameSellMethods;
    const auxFil = filters.current, auxPrevFil = filtersPrev.current;
    const auxKeywords1 = auxFil.keywords?.split(' ') || [];
    const auxKeywords2 = auxPrevFil.keywords?.split(' ') || [];
    sameKeywords = auxKeywords1.length === auxKeywords2.length;

    if (sameKeywords)
      sameKeywords = auxKeywords1.filter(aK1 => !auxKeywords2.includes(aK1)).length === 0;

    samePriceRange = auxFil.priceRange?.max === auxPrevFil.priceRange?.max
      && auxFil.priceRange?.min === auxPrevFil.priceRange?.min;

    sameTypes = auxFil.types?.length === auxPrevFil.types?.length
      && !Boolean(auxFil.types?.filter(f => !auxPrevFil.types?.includes(f))?.length)

    sameSellMethods = auxFil.sellMethods?.length === auxPrevFil.sellMethods?.length
      && !Boolean(auxFil.sellMethods?.filter(sM => !auxPrevFil.sellMethods?.includes(sM))?.length);

    setDisableUpdateBtn(sameKeywords && samePriceRange && sameTypes && sameSellMethods);
  }

  const showToolsMenuBtnClickHandler = () => {
    if (showToolsMenu) {
      toolsMenuRef.current?.classList.remove('show');
      toolsMenuRef.current?.classList.add('hide');
    } else setShowToolsMenu(true);
  }

  /** @type {React.AnimationEventHandler<HTMLDivElement>} */
  const toolsDivAnimationEndHandler = e => {
    e.stopPropagation();
    e.preventDefault();

    if (toolsMenuRef.current?.classList.contains('hide')) {
      toolsMenuRef.current.classList.remove('hide');
      setShowToolsMenu(false);
    }
  }

  /** The update results handler */
  const updateBtnClickHandler = async () => {
    setResults();
    // Update previous filters.
    filtersPrev.current = JSON.parse(JSON.stringify(filters.current));
    setDisableUpdateBtn(true);
  }

  const updateLocation = async () => {
    if (!inputLocationRef.current.value) return;

    setGLocState(0);

    await DAOServ.get('get_location', [inputLocationRef.current.value])
      .then(geoData => setCurrPos(geoData))
      .catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));

    setGLocState(1);
  }

  useEffect(() => {
    setSearchMethod();
    setShowFooter(false);

    // Checking if browser is allowed to use geolocation.
    if (!navigator.geolocation)
      setGLocState(-1);

    return () => setShowFooter(true);
  }, [setSearchMethod, setShowFooter]);

  useEffect(() => {
    const fetchEstates = async () => {
      /** @type {Estate[]} */
      const auxRes = [];
      try {
        const options = {
          filters: filters.current,
          tst: currSession.tst
        }

        /** @type {*[]} */
        const query = await DAOServ.post('search_estates', { options }, 'JSON');

        query.forEach(q => {
          const auxEst = new Estate();

          auxEst.setId(q['idestate']);
          auxEst.getLocation().setGeoData(q['g_location']);
          auxEst.setType(q['type'])
          auxEst.setSellMethod(q['sell_method']);

          if (q['bidding_status'])
            auxEst.getBidding().setStatus(true);

          auxRes.push(auxEst);
        });

        if (auxRes.length === 0)
          pushMessageHint({ message: 'No se encontraron resultados', type: 'warning' });
      } catch (err) {
        pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' });
      } finally {
        setResults(auxRes);
        setShowLoadingScreen(false);
      }
    }

    if (currSession.sessionStatus) {
      if (isGAPILoaded && results === undefined) {
        fetchEstates();
        setShowLoadingScreen(true)
      }
    }
  }, [currSession, isGAPILoaded, pushMessageHint, results, setShowLoadingScreen])

  useEffect(() => {
    const fetchEstate = async () => {
      const auxEstates = currEstates?.filter(e => e.getTitle() === undefined) ?? []
      const auxArr = currEstates?.filter(e => e.getTitle() !== undefined);

      for (const e of auxEstates) {
        const pub = await DAOServ.get('get_publishment_publ', [e.getId()]);
        const auxEst = new Estate(e);

        auxEst.getContract().setCharge(pub['charge_at_start']);
        auxEst.getImages().push(new GenericFile({ name: pub['cover'] }));
        auxEst.getBidding().setId(pub['idbidding']);
        auxEst.getContract().setId(pub['idcontract']);
        auxEst.setOwner(new User({ id: pub['idowner'] }));
        auxEst.getContract().setPayAmount(pub['pay_amount']);
        auxEst.getContract().setPayFrequency(pub['pay_frequency']);
        auxEst.getContract().setTermMethod(pub['term_method']);
        auxEst.getContract().setTerm(pub['term']);
        auxEst.setTitle(pub['title']);

        results[results.findIndex(r => r === e)] = auxEst;
        auxArr.push(auxEst);
      }

      setCurrEstates(auxArr);
    }

    if (currEstates?.filter(e => e.getTitle() === undefined).length > 0)
      fetchEstate();
  }, [currEstates, results]);

  return (<div className="search">
    <div className="map-container">
      {!isGAPILoaded && <LoadingPanel height='100%' />}
      {isGAPILoaded && <GoogleMap center={currPos}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        onLoad={onMapLoad}
        options={{
          streetViewControl: false,
          styles: [{
            "featureType": "poi.business",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }], clickableIcons: true
        }} zoom={5}>
        {/* The current position marker */}
        {!isCurrPosAtOrigin() && <Marker position={currPos}
          icon={{
            url: Icons.PointPosition,
            scaledSize: { height: 20, width: 20 },
            size: { height: 20, width: 20 }
          }} />}
        {renderResults()}
      </GoogleMap>}
    </div>
    {/* Tools side menu */}
    <div className={getToolsClassName()} onAnimationEnd={toolsDivAnimationEndHandler} ref={toolsMenuRef} >
      <div className="content">
        <div className="flex-box">
          <div className="child m3">
            {isGAPILoaded && <Autocomplete onPlaceChanged={() => { }}>
              <input disabled={gLocState === 0}
                ref={inputLocationRef}
                type="text"
                placeholder='Ubicación' />
            </Autocomplete>}
          </div>
          <div className="child auto-width m3">
            <Button disabled={gLocState === 0}
              empty
              icon={Icons.ArrowIcon}
              isWaiting={gLocState === 0}
              onClick={updateLocation}
              reduced
              title='Buscar' />
          </div>
          <div className="child auto-width m3">
            <Button disabled={gLocState === -1}
              empty
              icon={Icons.LocIcon}
              isWaiting={gLocState === 0}
              onClick={getLocation}
              reduced
              title='Usar mi ubicación' />
          </div>
        </div>
        <div className="box borderless shadowless">
          <h5 className="overset">Tipo de propiedad</h5>
          <div className="push-btns-container">
            <PushButton disabled={gLocState === 0} empty rounded value='Casas' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_HOUSE)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Departamentos de piso o lofts' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_DEPARTMENT)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Habitaciones, oficinas o locales' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_OFFICE)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Terrazas o salones de eventos' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_EVENT_HALL)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Almacenes' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_DEPOT)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Edificios' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_BUILDING)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Hoteles o moteles' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_HOTEL)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Lotes o terrenos' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_TERRAIN)} />
            <PushButton disabled={gLocState === 0} empty rounded value='Obras negras o grises' onClick={nV => estateTypeBtnClickHandler(nV, Estate.TYPE_BLACK_WORK)} />
          </div>
        </div>
        <div className="box borderless shadowless">
          <h5 className="overset">Método de promoción</h5>
          <div className="push-btns-container">
            {/* This line might be deprecated */}
            <PushButton borderless disabled={gLocState === 0} empty rounded value='Complejos' onClick={nV => estateSellMethodBtnClickHandler(nV, Estate.ON_COMPLEX)} />
            <PushButton borderless disabled={gLocState === 0} empty rounded value='En renta' onClick={nV => estateSellMethodBtnClickHandler(nV, Estate.ON_LEASE)} />
            <PushButton borderless disabled={gLocState === 0} empty rounded value='En venta' onClick={nV => estateSellMethodBtnClickHandler(nV, Estate.ON_SALE)} />
          </div>
        </div>
        <div className="box borderless shadowless">
          <h5 className="overset">Rango de precio</h5>
          <div className="flex-box">
            <div className="child m3">
              <Inputbar disabled={gLocState === 0}
                forceChangeRef={inputMaxPriceRollback}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                maxLength={10}
                onChange={input => priceRangeIbChangeHandler('max', input)}
                placeholder={{ default: 'Máximo' }} />
            </div>
            <div className="child m3">
              <Inputbar disabled={gLocState === 0}
                forceChangeRef={inputMinPriceRollback}
                filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
                maxLength={10}
                onChange={input => priceRangeIbChangeHandler('min', input)}
                placeholder={{ default: 'Mínimo' }} />
            </div>
          </div>
        </div>
        <div className="box borderless shadowless">
          <Inputbar disabled={gLocState === 0}
            filters={[{ regExp: Global.REGEXP_FILTER_FORBIDDEN_SYMBOLS }]}
            maxLength={255}
            onBlur={input => input?.replace(/\s+/g, ' ')?.replace(/^\s|\s$/g, '')}
            onChange={keywordsIbChangeHandler}
            placeholder={{ default: 'Palabras clave' }} />
          <Hintbox icon={Icons.InfoIcon}
            message='Las palabras que ingreses aquí se buscarán en títulos y descripciones.' />
        </div>
      </div>
      <div className="update-btn-container">
        <Button disabled={gLocState === 0 || disableUpdateBtn}
          empty
          fullWidth
          icon={Icons.UpdateIcon}
          onClick={updateBtnClickHandler}
          rounded
          value={'Actualizar'} />
      </div>
    </div>
    <div className="show-tools-btn-container">
      <Button disabled={gLocState === 0}
        empty
        icon={!showToolsMenu ? Icons.MenuIcon : undefined}
        onClick={showToolsMenuBtnClickHandler}
        value={!showToolsMenu ? undefined : '>'} />
    </div>
    {currEstates !== undefined && <div className="estate-container show">
      <div className="top-bar">
        <h4 className="overset">En esta locación</h4>
        <Button borderless
          empty
          icon={Icons.CloseIcon}
          onClick={() => setCurrEstates()}
          reduced
          typeRender='error' />
      </div>
      {currEstates === undefined && <LoadingBlock noBackground />}
      {currEstates !== undefined && currEstates.length > 0 && currEstates.map(cE => {
        return <SearchItem key={`estate-${cE.getId()}`} estate={cE.getTitle() !== undefined ? cE : undefined}
          onClick={() => navigate(`${Global.PATH_PUBLIC_ESTATE}/${cE.getId()}`)} />
      })}
      {currEstates?.length === 0 && <NotFoundBox img={Icons.KevinNotFoundImg} message='Nada disponible aquí. :(' />}
    </div>}
    {showDialog === true && <Dialog confirmBtn={{ value: 'Conceder' }}
      message='Necesitamos acceso a tu ubicación para mostrarte las propiedades más cercanas a tu posición.'
      onHide={() => setShowDialog(false)}
      onResolve={() => {
        localStorage.setItem('locationPass', 'agreed');
        getLocation();
      }}
      rejectBtn={{ type: 'error', value: 'Denegar' }}
      renderButtonsEmpty
      renderButtonsRounded />}
  </div>);
}

export default Search;