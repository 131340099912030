import '../styles/set-field.css';
import { globalContext } from '../../context/GlobalContext';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Global from '../../objects/Global';
import UIRender from '../../objects/UIRender';
import * as Icons from '../../assets/images';
import Inputbar from '../Inputbar';
import Selectbar from '../Selectbar';
import Hintbox from '../Hintbox';
import ErrHandler from '../../objects/ErrHandler';

/** PreviousInputObject typedef
 * @typedef {Object}  PreviousInputObject
 * @property {import('../Inputbar').InputbarPlaceholderObject} [placeholder] A custom placeholder for the input.
 * @property {(input?: string) => string} [priori] A callback function
 * that will be called for input to pass through before being compared to previousValue.
 * This is useful when previousValue is encrypted an new input needs to
 * pass through an algorithm before being compared.
 * @property {string} value The previous value to be compared. If undefined, callback
 * function will be ignored. This isn't the defaultValue for the inputbar!
 */

/** InputObject typedef.
 * @typedef {Object} InputObject
 * @property {import('../Inputbar').InputbarPlaceholderObject} [confirmation] A boolean comparision
 * will be done. If true and required is true, confirmation Inputbar will be rendered
 * and required. The value of this attribute will be used as placeholder for the input.
 * @property {string} [defaultValue] A default value to be shown in the inputbar on render.
 * @property {import('../Inputbar').InputbarFilterObject[]} [filters] Filters for inputbar. This
 * attribute is ignored if props.type is not undefined, 'text' or any invalid type.
 * @property {(input: string) => boolean} [isValid] Valitdation callback function for
 * inputbar. This attribute will be ignored if props.type is 'tel'
 * @property {number} [maxLength] Maximum length for inputbar.
 * @property {number} [minLength] Minimum length for inputbar.
 * @property {(input?: string) => string} [onBlur] onBlur callback function for inputbar.
 * @property {import('../Inputbar').InputbarPlaceholderObject} [placeholder] Placeholder for inputbar.
 * @property {PreviousInputObject} [previousValue] If defined, previous Inputbar
 * will be rendered and required.
 * @property {boolean} [repeatable] If true, new value can be the same as
 * defaultValue (or previous value).
 * @property {boolean} [required] If not true, setField will accept empty fields.
 * @property {'capitalize'|'lowercase'|'uppercase'} [textTransform] Text transform for the
 * inputbar.
 * @property {'email'|'numeric'|'tel'|'password'|'date'} [type] A type for the value. If type is set to 'tel',
 * be sure to send country code, prefix and number joined with _ symbols in defaultValue
 * (if required), otherwise defaultValue will be ignored. If undefined, value will be
 * treaten as text input.
 */

/** InputRefObject typedef
 * @typedef {Object} InputRefObject
 * @property {string|PhoneObject} value The current value.
 * @property {{current: (input?: string) => void}} rollback A callback function
 * to replace its current value.
 */

/** PhoneObject typedef
 * @typedef {Object} PhoneObject
 * @property {string} number The phone number
 * @property {string} prefix The full prefix (code and prefix. f.e: MX_+52).
 */

/** SelectObject typedef.
 * @typedef {Object} SelectObject
 * @property {*} [defaultValue] A default value.
 * @property {string} placeholder A placeholder for the Selectbar.
 * @property {boolean} [required] If true, compo must content a value to enable submit.
 * @property {import('../Selectbar').OptionObject[]} [values] Values only for Selectbar.
 */

/** SetFieldActionObject typedef
 * @typedef {Object} SetFieldActionObject
 * @property {*} newValue The new value.
 * @property {*} oldValue The old value (default value or previous value).
 */

/** SetFieldPropsObject typedef
 * @typedef {Object} SetFieldPropsObject
 * @property {(pkg: SetFieldActionObject) => Promise<*>} action A callback function that will
 * be triggered when user clicks submit button. When action resolves, SetField will hide and trigger
 * props.onResolve (if defined). When action rejects, SetField will trigger props.onReject
 * (if defined). This callback function is required, otherwise app will crash.
 * @property {string} [props.hint] If defined, compo shows a Hintbox compo.
 * @property {InputObject|SelectObject} options Input options for the Inputbar or Selectbar.
 * @property {() => void} props.onHide A callback function that activates when compo is hidden.
 * @property {(err?: string|Error) => void} [onReject] A callback function that will be
 * triggered when props.action rejects, returning the error within.
 * @property {(newVal: string) => void} [onResolve] A callback function that will be triggered
 * when props.action resolves. It will receive new value.
 */

/** Renders a SetField popup compo.
 * @param {SetFieldPropsObject} props The props object.*/
const SetField = props => {
  /** Gets the options object typedef.
   * @returns 1 if InputObject, 2 if SelectObject.
   */
  const getOptionsTypedef = useCallback(() => {
    const keys = Object.keys(props.options);
    if (keys.length >= 2 && keys.length <= 4 && keys.includes('values'))
      return 2;
    else return 1;
  }, [props.options]);

  // *** useContext ***
  const { pushMessageHint } = useContext(globalContext);
  // *** useState ***
  const [disableUI, setDisableUI] = useState(false); // Disable global UI.
  const [disableSubmit, setDisableSubmit] = useState(true); // Disable submit.
  const [disableConfInput, setDisableConfInput] = useState(true); // Disable conf input.
  const [prefixes, setPrefixes] = useState(/** @type {import('../../Signup').PrefixObject[]} */(undefined));
  const [readyToRender, setReadyToRender] = useState(false);
  const [showInputs, setShowInputs] = useState(Boolean(props.options?.type !== 'password'));
  // *** useRef ***
  const confInput = useRef(/** @type {InputRefObject} */({ value: undefined, rollback: {} }));
  const currInput = useRef(/** @type {InputRefObject} */(undefined));
  const id = useRef('set-field-popup');
  const prevInput = useRef(/** @type {InputRefObject} */({ value: undefined, rollback: {} }));
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));

  const closeBtnHandleOnClick = () => {
    if (!disableUI) UIRender.hideElement(popup.current);
  }

  const confInputHandleOnChange = input => {
    confInput.current.value = input;
    requestEnableSubmit();
  }

  const currInputHandleOnChange = input => {
    if (props.options.type !== 'tel')
      currInput.current.value = input;
    else
      currInput.current.value.number = input;

    if (props.options.confirmation) {
      confInput.current.value = undefined;
      confInput.current.rollback.current('');
    }

    setDisableConfInput(!input);
    requestEnableSubmit();
  }

  const prevInputHandleOnChange = input => {
    prevInput.current.value = input
    requestEnableSubmit();
  }

  const getPrefixDefaultValue = () => {
    const phone = props.options.defaultValue?.split('_');
    const prefix = prefixes.find(p => p.code === phone[0] && p.dial_code === phone[1]);

    return prefix && `${prefix.code}_${prefix.dial_code}`;
  }

  const getInputMode = () => {
    switch (props.type) {
      case 'date': return 'date';
      case 'email': return 'email';
      case 'number': return 'numeric';
      case 'password': return 'password';
      case 'tel': return 'tel';
      default: return undefined;
    }
  }

  const getType = () => {
    switch (props.options.type) {
      case 'date': return 'date';
      case 'email': return 'email';
      case 'password': return showInputs ? 'text' : 'password';
      case 'tel': return 'tel';
      default: return 'text';
    }
  }

  /** @param {string} [input] */
  const isValid = input => {
    /** @type {InputObject} */
    const options = props.options;

    const flag = (options.required && !input) || compareToPrevious(input);

    return !flag && (!props.options.isValid || props.options.isValid(input));
  }

  /** Compares given input to previous values (previousValue or, if undefined, defaultValue).
   * Only valid for input compos. This function won't pass through isValid callback.
   * @param {string} [input] current input.
   * @returns True if given input is the same than previousValue or, if undefined, defaultValue.
   */
  const compareToPrevious = input => {
    /** @type {InputObject} */
    const options = props.options;

    if (options.previousValue?.value) {
      const value = options.previousValue.priori
        ? options.previousValue.priori(input)
        : input;

      return value === options.previousValue.value;
    } else return false;
  }

  const prefixHandleOnChange = option => {
    currInput.current.value.prefix = option;
    requestEnableSubmit();
  }

  const renderContent = () => {
    if (getOptionsTypedef() === 2) {
      /** @type {SelectObject} */
      const options = props.options;

      return (
        <div className="content">
          <Selectbar defaultValue={options.defaultValue}
            forceChangeRef={currInput.current.rollback}
            options={options.values}
            disabled={disableUI}
            onChange={currInputHandleOnChange}
            placeholder={options.placeholder}
            required={options.required} />
        </div>
      );
    } else {
      /** @type {InputObject} */
      const options = props.options;

      return (
        <div className="content">
          {/* Previous field */}
          {options.previousValue?.value && <Inputbar onChange={prevInputHandleOnChange}
            disabled={disableUI}
            filters={options.filters}
            forceChangeRef={prevInput.current.rollback}
            isValid={compareToPrevious}
            maxLength={options.maxLength}
            minLength={options.minLength}
            placeholder={{
              default: options.previousValue.placeholder.default || 'Ingresa el valor antiguo',
              onIsValidFail: options.previousValue.placeholder.onIsValidFail || 'La entrada no coincide'
            }} requestFocus
            required
            type={getType()} />}
          {/* Current field */}
          <div className="flex-box">
            {/* Prefix select */}
            {options.type === 'tel' && <div className="child auto-width m3">
              <Selectbar defaultValue={getPrefixDefaultValue()}
                disabled={disableUI}
                options={prefixes.map(p => {
                  return {
                    displayValue: `${p.name} ${p.dial_code}`,
                    value: `${p.code}_${p.dial_code}`
                  }
                })} onChange={prefixHandleOnChange}
                placeholder='Prefijo'
                required
                width={115} />
            </div>}
            <div className="child">
              <Inputbar onChange={currInputHandleOnChange}
                defaultValue={props.options.type === 'tel'
                  ? currInput.current.value.number
                  : currInput.current.value}
                disabled={disableUI}
                filters={options.filters}
                forceChangeRef={currInput.current.rollback}
                inputMode={getInputMode()}
                isValid={isValid}
                maxLength={options.maxLength}
                minLength={options.minLength}
                onBlur={options.onBlur}
                placeholder={options.placeholder}
                requestFocus={options.type !== 'password'}
                required={options.required}
                stopPropagation
                textTransform={options.textTransform}
                type={getType()} />
            </div>
          </div>
          {/* Confirm input */}
          {options.required && Boolean(options.confirmation) && <Inputbar onChange={confInputHandleOnChange}
            disabled={disableUI || disableConfInput}
            filters={options.filters}
            forceChangeRef={confInput.current.rollback}
            inputMode={getInputMode()}
            isValid={input => input === currInput.current.value}
            onBlur={options.onBlur}
            placeholder={{
              default: options.confirmation.default || 'Ingresa nuevamente la entrada',
              onIsValidFail: options.confirmation.onIsValidFail || 'Los campos no coinciden'
            }} required
            stopPropagation
            textTransform={options.textTransform}
            type={getType()} />}
          {/* Show inputs values button */}
          {options.type === 'password' && <Button borderless
            disabled={disableUI}
            empty
            fullWidth
            icon={showInputs ? Icons.HideIcon : Icons.ShowIcon}
            onClick={() => setShowInputs(!showInputs)}
            reduced
            rounded
            value={showInputs ? 'Ocultar todo' : 'Mostrar todo'} />}
        </div>
      );
    }
  }

  const requestEnableSubmit = () => {
    if (props.options.type === 'tel') {
      /** @type {PhoneObject} */
      const value = currInput.current.value;
      const phone = `${value.prefix}_${value.number}`;
      const same = phone === props.options.defaultValue;
      const empty = !value.number || !value.prefix;
      setDisableSubmit(empty || same);
    } else if (props.options.type === 'password') {
      const allSet = confInput.current
        && currInput.current.value
        && (!props.options.previousValue || prevInput.current.value);
      const conf = currInput.current.value === confInput.current.value;
      const same = currInput.current.value === prevInput.current.value;
      setDisableSubmit(!allSet || !conf || same);
    } else {
      const same = currInput.current.value === props.options.defaultValue;
      setDisableSubmit(!currInput.current.value || same);
    }
  }

  const submitBtnHandleOnClick = async () => {
    if (disableSubmit) return; // Return if submit is disabled.

    setDisableUI(true); // Disable set field UI.
    UIRender.blurFocus(); // Blur current focus.
    /** @type {SetFieldActionObject} */
    const pkg = {}; // Value for action callback function.

    switch (props.type) {
      case 'tel': {
        /** @type {PhoneObject} */
        const phone = currInput.current.value;

        pkg.newValue = `${phone.prefix}_${phone.number}`;
        pkg.oldValue = props.options.previousValue?.value || props.options.defaultValue;
        pkg.oldValue = pkg.oldValue.replace('-', '_');
        break;
      } default: {
        pkg.newValue = currInput.current.value;
        pkg.oldValue = prevInput.current.value
          || props.options.previousValue?.value
          || props.options.defaultValue;
      }
    }

    // Executing action callback.
    await props.action(pkg)
      .then(() => { // Promise resolve. Disposing popup.
        props.onResolve && props.onResolve(pkg.newValue);
        UIRender.hideElement(popup.current);
      }).catch(err => { // Promise reject. Trigger onReject.
        props.onReject && props.onReject(err);
        setDisableUI(false);
      });
  }

  useEffect(() => {
    const fetchPrefixes = async () => {
      try {
        return Array.from(await (await fetch(Global.FETCH_PREFIXES)).json());
      } catch (err) {
        ErrHandler.parseError(err);
        return Promise.reject('No se pudo obtener la lista de prefijos. Inténtalo más tarde');
      }
    }

    const init = async () => {
      try {
        if (getOptionsTypedef() === 1) { // Inputbar.
          /** @type {InputObject} */
          const options = props.options;

          if (props.options.type === 'tel') {
            setPrefixes(await fetchPrefixes());

            /** @type {PhoneObject} */
            const defaultValue = {};

            if (/[A-Z]{2}_\+[0-9]{2,4}_[0-9]{10}/.test(options.defaultValue)) {
              const auxPhone = options.defaultValue.split('_');

              defaultValue.number = auxPhone[2];
              defaultValue.prefix = `${auxPhone[0]}_${auxPhone[1]}`;
              currInput.current = { value: defaultValue, rollback: {} };
            } else currInput.current = { value: '', rollback: {} };
          } else currInput.current = { value: options.defaultValue ?? '', rollback: {} }
        } else { // Selectbar.
          /** @type {SelectObject} */
          const options = props.options;
          currInput.current = { value: options.defaultValue, rollback: {} };
        }

        setReadyToRender(true);
      } catch (err) {
        pushMessageHint({ message: err, type: 'error' });
        UIRender.hideElement(popup.current)
      }
    }

    const auxId = id.current;
    const parent = popup.current.parentNode;
    // UIRender.disableSiblings options.
    const options = { footer: true, navbar: true };
    UIRender.disableGlobalScroll(auxId); // Disable global scroll.
    UIRender.disableSiblings(popup.current, options); // Disable siblings.

    init();

    return () => {
      UIRender.enableChilds(parent, options, auxId);
      UIRender.enableGlobalScroll(auxId);
    }
  }, [getOptionsTypedef, props.options, pushMessageHint]);

  return (
    <div className="popup-wrapper set-field-popup" id={id.current} ref={popup}
      onAnimationEnd={e => e.target === popup.current && UIRender.isHidden(popup.current) && props.onHide()} >
      <div className="popup">
        <div className="popup-content">
          <div className="top-bar">
            <h3 className='highlight'>Editor de campos</h3>
            <Button disabled={(props.options.type === 'tel' && !prefixes) || disableUI}
              borderless
              empty
              icon={Icons.CloseIcon}
              onClick={closeBtnHandleOnClick}
              reduced
              rounded
              typeRender='error' />
          </div>
          {!readyToRender && <div className="content">
            <span className={`animated-figure purple ${Global.getRandom(['f1', 'f2', 'f3'])}`} />
          </div>}
          {readyToRender && renderContent()}
          {/* Hint box */}
          {props.hint && readyToRender && <Hintbox message={props.hint} empty icon={Icons.InfoIcon} />}
          <Button disabled={disableSubmit}
            isWaiting={disableUI}
            className='empty animate'
            value='Guardar'
            onClick={submitBtnHandleOnClick}
            onWaitValue='Guardando...'
            icon={Icons.SaveIcon} />
        </div>
      </div>
    </div>
  );

}

export default SetField;