import { useCallback, useEffect, useRef, useState } from "react";
import Estate from "../../objects/Estate";
import Inputbar from "../../components/Inputbar";
import Selectbar from "../../components/Selectbar";
import Switch from "../../components/Switch";
import PetAssistant from "../../components/PetAssistant";
import Global from "../../objects/Global";
import Hintbox from "../../components/Hintbox";
import { InfoIcon, WarningIcon } from "../../assets/images";

/** Renders a Step2 compo for CreateEstate
 * @param {Object} props The props object.
 * @param {(step: number) => void} props.addIgnoredStep A callback function that adds
 * an ignored step to the ignored steps array.
 * @param {Estate} [props.complex] The complex which the new estate will be related. This
 * prop uses the spaces attributes for total spaces specification.
 * @param {boolean} [props.enableBidding] A boolean value used to show bidding option.
 * @param {Estate} props.estate the estate object that's being created.
 * @param {(step: number)} props.removeIgnoredStep A callback function that removes
 * an ignored step to the ignored steps array.
 * @param {(val: boolean) =>} props.setEnableNextBtn A callback function used to enable
 * or disable next step button.
 * @param {number} props.today The current day in milliseconds.
 */
const Step2 = props => {
  // *** useCallback ***
  /** Obtains the max spaces based in free spaces from complex */
  const getMaxSpaces = useCallback(() => {
    if (props.complex.getTotalSpaces() < 0) return 1000
    else return props.complex.getTotalSpaces();
  }, [props.complex]);

  const requestNextStep = useCallback(() => {
    const bidding = !props.estate.getBidding().getStatus()
      || props.estate.getBidding().getEndDate();
    const typeValid = Estate.isTypeValid(props.estate.getType());
    const sellMethodValid = props.estate.getSellMethod() === Estate.ON_SALE
      || (props.estate.getSellMethod() === Estate.ON_LEASE && Estate.canBeOnLease(props.estate))
      || (props.estate.getSellMethod() === Estate.ON_COMPLEX && Estate.canBeComplex(props.estate));
    const spaces = props.complex === undefined
      || props.estate.getTotalSpaces() <= getMaxSpaces();

    requestCallback.current(typeValid && spaces && sellMethodValid && bidding);
  }, [props.complex, props.estate, getMaxSpaces]);
  // *** useRef ***
  const requestCallback = useRef(props.setEnableNextBtn);
  // *** useState ***
  const [sellMethod, setSellMethod] = useState(props.estate.getSellMethod());
  const [type, setType] = useState(props.estate.getType());

  const biddEndHandleOnChange = input => {
    props.estate.getBidding().setEndDate(input);
    requestNextStep();
  }

  const biddingHandleOnChange = async newState => {
    props.estate.getBidding().setStatus(newState);
    if (newState) props.estate.setStatus(Estate.STATUS_ACT);
    requestNextStep();

    return Promise.resolve();
  }

  const getSpacesIbrMaxLength = () => {
    if (props.complex.getTotalSpaces() < 0) return 4;
    else {
      if (props.complex.getTotalSpaces() < 10) return 1;
      else if (props.complex.getTotalSpaces() < 100) return 2;
      else if (props.complex.getTotalSpaces() < 1000) return 3;
      else return 4;
    }
  }

  const getSellMethodOptions = () => {
    /** @type {import('../../components/Selectbar').OptionObject[]} */
    const options = [{ displayValue: 'En venta', value: Estate.ON_SALE }];

    if (Estate.canBeOnLease(props.estate))
      options.push({ displayValue: 'En renta', value: Estate.ON_LEASE });

    if (props.complex === undefined && Estate.canBeComplex(props.estate))
      options.push({ displayValue: 'Vender o rentar por subdivisiones', value: Estate.ON_COMPLEX });

    return options;
  }

  const sellMethodHandleOnChange = option => {
    props.estate.setSellMethod(option);

    if (option === Estate.ON_COMPLEX) {
      props.estate.getBidding().setStatus(false); // Disable bidding.
      props.addIgnoredStep(2); // Price step.
      props.addIgnoredStep(4); // Included services step.
      props.addIgnoredStep(5); // Contact info step.
    } else {
      props.removeIgnoredStep(2); // Price step.
      props.removeIgnoredStep(5); // Contact info step.

      if (option === Estate.ON_LEASE && props.estate.hasService()) { // On lease sell method.
        props.removeIgnoredStep(4); // Included services step.
      } else { // On sale / complex sell method.
        props.addIgnoredStep(4) // Included services step.
      }
    }

    setSellMethod(option);
  }

  const totalSpacesHandleOnChange = input => {
    props.estate.setTotalSpaces(input);
    requestNextStep();
  }

  const typeHandleOnChange = option => {
    props.estate.setType(option);
    props.estate.setSellMethod();
    setType(option);
    setSellMethod('');
  }

  useEffect(() => { requestNextStep() }, [requestNextStep, sellMethod, type]);

  return (
    <div className="popup-content create-estate-content" id="step-1" >
      <PetAssistant pet="kevin" message="Cuéntame qué tipo de propiedad es y qué quieres hacer con ella." />
      <div className="flex-box wrap">
        <div className="child m3">
          <Selectbar defaultValue={props.estate.getType()}
            onChange={typeHandleOnChange}
            options={Estate.getTypes().map(({ displayName: displayValue, value }) => {
              return { displayValue, value }
            })} placeholder="Tipo de propiedad"
            required />
        </div>
        <div className="child m3">
          <Selectbar options={getSellMethodOptions()}
            defaultValue={props.estate.getSellMethod()}
            disabled={!type}
            forceChangeValue={sellMethod}
            onChange={sellMethodHandleOnChange}
            placeholder="Método de promoción" />
        </div>
      </div>
      {props.complex !== undefined && getMaxSpaces() > 0 &&
        <div className="flex-box wrap">
          <div className="child m3">
            <Inputbar defaultValue={props.estate.getTotalSpaces() || ''}
              filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }, { regExp: /^0+/ }]}
              inputMode="numeric"
              isValid={input => Number(input) > 0 && Number(input) <= getMaxSpaces()}
              maxLength={getSpacesIbrMaxLength()}
              onChange={totalSpacesHandleOnChange}
              placeholder={{
                default: `Total disponibles (máx. ${getMaxSpaces()})`,
                onIsValidFail: `Valor fuera de rango (1 - ${getMaxSpaces()})`
              }} required />
          </div>
          <div className="child">
            <Hintbox icon={InfoIcon}
              message={'Es la cantidad de propiedades iguales que tienes disponibles a la venta o renta.'} />
          </div>
        </div>}
      {props.enableBidding && sellMethod !== Estate.ON_COMPLEX && <div className="flex-box wrap">
        <div className="child auto-width m3">
          <Switch defaultValue={props.estate.getBidding().getStatus()}
            disabled={!sellMethod || sellMethod === Estate.ON_COMPLEX}
            action={biddingHandleOnChange}
            placeholder={{ default: 'Subastar', onChecked: 'En subasta' }} />
        </div>
        <div className="child m3">
          <Inputbar onChange={biddEndHandleOnChange}
            defaultValue={Global.transformDateForInput(props.estate.getBidding().getEndDate())}
            disabled={!props.estate.getBidding().getStatus()}
            isValid={input => {
              if (!input) return false;

              const date = Date.parse(input);
              // Bidding's duration must be set from 14 to 30 days.
              return date >= props.today + 1296000000 && date <= props.today + 2592000000;
            }} placeholder={{
              default: props.estate.getBidding().getStatus()
                ? 'Fin de la subasta'
                : 'No requerido',
              onIsValidFail: props.estate.getBidding().getStatus()
                ? 'La subasta debe durar de 14 a 30 días a partir de hoy'
                : 'No requerido'
            }}
            required={props.estate.getBidding().getStatus()}
            type="date" />
        </div>
      </div>}
      {props.enableBidding && sellMethod === Estate.ON_COMPLEX && <Hintbox
        icon={WarningIcon}
        message={'Las propiedades que son vendidas o rentadas por subdivisiones no pueden ser'
          + ' subastadas, pero puedes subastar sus subdivisiones.'}
        type="warning" />}
    </div>
  );
}

export default Step2;