import './styles/switch.css';
import { useEffect, useRef, useState } from 'react';

/** InputbarPlaceholderObject typedef
 * @typedef {Object} InputbarPlaceholderObject
 * @property {string} [default] Default placeholder for the compo (when it's unchecked). Assign
 * an empty string to render no placeholder (onChecked option will be ignored).
 * @property {string} [onChecked] Placeholder for the compo when is checked. If undefined or empty,
 * default will be replacing this attribute.
 */

/** Renders a Switch compo.
 * @param {Object} props The props object
 * @param {(newState: boolean, id?: string) => Promise<void>} props.action An asynchronous function that will
 * be triggered when compo changed (onChageEvent). Compo will be on wait state until the Promise is fullfilled,
 * so no need for set isWaiting value manually. Resolve the promise for the compo to be toggled. Reject the
 * promise for the compo to not change.
 * @param {boolean} [props.disabled] used to disable compo.
 * @param {boolean} [props.defaultValue] If true, compo will be checked.
 * @param {React.MutableRefObject<(val?: boolean) => void>} [props.forceChangeRef] A ref.
 * A callback function will be assigned to its current value. Call current to force input value to
 * change (f.e. <ref_def.current(<new_value>)). Using the current callback function won't trigger
 * props.action nor change disabled or isWaiting states.
 * @param {boolean} [props.forceChangeValue] Use this attribute to force value change (use a
 * useState var for this to work properly). This won't trigger props.action nor change disabled
 * or isWaiting states.
 * @param {string} [props.id] Custom id for the compo.
 * @param {boolean} [props.isWaiting] If true, compo will render in wait state.
 * @param {(err: *) => void} [props.onActionReject] When props.action rejects, this callback function will
 * be triggered (if specified), sending the error triggered in props.action.
 * @param {InputbarPlaceholderObject} [props.placeholder] A placeholder for the compo. If undefined, 'Switch'
 * will be the default placeholder globally.
 */
const Switch = props => {
  // *** useRef ***
  const auxToggleRef = useRef(Boolean(props.defaultValue));
  const switchRef = useRef(/** @type {HTMLInputElement} */(undefined));
  // *** useState ***
  const [toggle, setToggle] = useState(auxToggleRef.current);
  const [isWaiting, setIsWaiting] = useState(Boolean(props.isWaiting));

  const getClassName = () => {
    let result = 'switch';

    if (isWaiting) result += ' wait';

    return result;
  }

  const getPlaceholder = () => {
    if (!toggle) return props.placeholder?.default || 'Switch';
    else return props.placeholder?.onChecked || props.placeholder?.default || 'Switch';
  }

  const switchHandleOnChange = () => {
    switchRef.current.value = toggle;

    if (!props.disabled) {
      setIsWaiting(true);

      props.action(!toggle, props.id)
        .then(() => {
          auxToggleRef.current = !toggle;
          setToggle(!toggle);
        }).catch(err => {
          if (props.onActionReject) props.onActionReject(err);
        }).finally(() => setIsWaiting(false));
    }
  }

  useEffect(() => {
    auxToggleRef.current = Boolean(props.defaultValue);
    setToggle(auxToggleRef.current);
  }, [props.defaultValue]);

  useEffect(() => {
    if (typeof props.forceChangeRef === 'object') {
      props.forceChangeRef.current = newToggle => {
        if (newToggle !== auxToggleRef.current) {
          auxToggleRef.current = Boolean(newToggle);
          setToggle(auxToggleRef.current);
        }
      }
    }
  }, [props.forceChangeRef]);

  useEffect(() => {
    auxToggleRef.current = Boolean(props.forceChangeValue);
    setToggle(auxToggleRef.current)
  }, [props.forceChangeValue]);

  useEffect(() => { setIsWaiting(props.isWaiting) }, [props.isWaiting])

  return (<div className={getClassName()} disabled={props.disabled}>
    <label className="switch" disabled={isWaiting || props.disabled}>
      <input id={props.id}
        checked={toggle}
        disabled={props.disabled}
        onChange={switchHandleOnChange}
        ref={switchRef}
        type="checkbox" />
      <span className="slider" />
    </label>
    {(props.placeholder === undefined || props.placeholder.default !== '') && <p
      className={!toggle ? 'overset' : ''}>
      {getPlaceholder()}
    </p>}
  </div>);
}

export default Switch;