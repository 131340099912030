import { useCallback, useEffect, useRef } from 'react';
import { MailIcon, PhoneIcon, UserIcon } from '../../assets/images';
import Global from '../../objects/Global';
import Inputbar from '../../components/Inputbar';
import PetAssistant from '../../components/PetAssistant';
import Selectbar from '../../components/Selectbar';
import Switch from '../../components/Switch';
import Persona from '../../objects/Persona';

/** Renders a Step5 compo for CreateEstate
 * @param {Object} props The props object.
 * @param {import('../../objects/Persona').default} props.contactInfo
 * @param {import('../../objects/Estate').default} props.estate An estate instance.
 * @param {(val: boolean) =>} props.setEnableNextBtn A callback function used to enable
 * or disable next step button.
 * @param {{name: string, dial_code: string, code:string}[]} props.prefixes An array
 * of phone prefixes.
 */
const Step6 = props => {
  // *** useRef ***
  const contactInfo = useRef(props.estate.getContactInfo() === -1
    ? props.contactInfo
    : props.estate.getContactInfo());
  /** @type {React.MutableRefObject<function(boolean)>} */
  const enableNextStepCallback = useRef(props.setEnableNextBtn);
  const rollbackCallbacks = useRef([useRef(), useRef(), useRef(), useRef()]);

  /** Input change event handler.
   * @param {'name'|'prefix'|'number'|'email'} id 
   * @param {string} input 
   * @returns 
   */
  const inputHandleOnChange = (id, input) => {
    switch (id) {
      case 'name': {
        contactInfo.current.setFirstName(input);
        break;
      } case 'prefix': {
        const inputSplit = input?.split('_');
        if (inputSplit) contactInfo.current.setPhone({ code: inputSplit[0], prefix: inputSplit[1] });
        break;
      } case 'number': {
        contactInfo.current.setPhone({ number: input });
        break;
      } case 'email': {
        contactInfo.current.setEmail(input);
        break;
      } default: return;
    }

    requestNextStep();
  }

  const IsSwitchActive = () => {
    return props.estate.getContactInfo() === -1;
  }

  const requestNextStep = useCallback(() => {
    enableNextStepCallback.current(props.estate.getContactInfo() === -1 || (contactInfo.current.getName()
      && contactInfo.current.getEmail()
      && contactInfo.current.getFullPrefix()
      && contactInfo.current.getPhone().number
    ));
  }, [props.estate]);

  /** @param {boolean} newState */
  const switchCInfoHandleOnChange = async newState => {
    if (newState) {
      props.estate.setContactInfo(-1);
      contactInfo.current = props.contactInfo;
      rollbackCallbacks.current[0].current(props.contactInfo.getName());
      rollbackCallbacks.current[1].current(props.contactInfo.getFullPrefix());
      rollbackCallbacks.current[2].current(props.contactInfo.getPhone().number);
      rollbackCallbacks.current[3].current(props.contactInfo.getEmail());
    } else {
      props.estate.setContactInfo(new Persona());
      contactInfo.current = props.estate.getContactInfo();
      rollbackCallbacks.current.forEach(cbFX => cbFX.current(''));
    }

    requestNextStep();
    return Promise.resolve();
  }

  useEffect(() => { requestNextStep() }, [requestNextStep]);

  return (
    <div className="popup-content create-estate-content" id="step-5">
      <PetAssistant pet='kevin' animOnDwarf
        message={'Estamos por terminar. ¿Qué información quieres que las personas utilicen para'
          + ' contactarse contigo?'} />
      <div className="flex-box jc-left m3">
        <Switch defaultValue={IsSwitchActive()}
          action={switchCInfoHandleOnChange}
          placeholder={{ default: 'Usar mis datos personales' }} />
      </div>
      <div className="flex-box m3">
        <div className="child">
          <Inputbar defaultValue={contactInfo.current.getName()}
            disabled={IsSwitchActive()}
            filters={[{ regExp: Global.REGEXP_FILTER_SYMBOLS }]}
            forceChangeRef={rollbackCallbacks.current[0]}
            icon={UserIcon}
            maxLength={100}
            minLength={2}
            onBlur={input => input?.trim()?.replace(/\s+/g, ' ')}
            onChange={input => inputHandleOnChange('name', input)}
            placeholder={{ default: 'Nombre', onMinLengthFail: 'El nombre debe contener 2 o más caracteres' }}
            required
            textTransform='capitalize' />
        </div>
      </div>
      <div className="flex-box m3">
        <div className="child auto-width">
          <Selectbar defaultValue={contactInfo.current.getFullPrefix()}
            disabled={IsSwitchActive()}
            forceChangeRef={rollbackCallbacks.current[1]}
            options={props.prefixes.map(p => {
              return {
                displayValue: `${p.name} ${p.dial_code}`,
                value: `${p.code}_${p.dial_code}`
              }
            })}
            onChange={option => inputHandleOnChange('prefix', option)}
            placeholder='Prefijo'
            required
            width={115} />
        </div>
        <div className="child">
          <Inputbar defaultValue={contactInfo.current.getPhone().number}
            disabled={IsSwitchActive()}
            filters={[{ regExp: Global.REGEXP_FILTER_INTEGER }]}
            forceChangeRef={rollbackCallbacks.current[2]}
            icon={PhoneIcon}
            inputMode='tel'
            isValid={input => Global.REGEXP_PHONE_NUMBER.test(input) && !/^0{5,}/.test(input)}
            maxLength={10}
            minLength={10}
            onChange={input => inputHandleOnChange('number', input)}
            placeholder={{
              default: 'Teléfono',
              onIsValidFail: 'Teléfono inválido',
              onMinLengthFail: 'El teléfono debe contener 10 dígitos'
            }}
            required />
        </div>
      </div>
      <div className="flex-box">
        <div className="child">
          <Inputbar defaultValue={contactInfo.current.getEmail()}
            disabled={IsSwitchActive()}
            filters={[{ regExp: Global.REGEXP_FILTER_EMAIL }]}
            forceChangeRef={rollbackCallbacks.current[3]}
            icon={MailIcon}
            inputMode='email'
            isValid={input => Global.REGEXP_EMAIL.test(input)}
            maxLength={255}
            onChange={input => inputHandleOnChange('email', input)}
            placeholder={{ default: 'Correo electrónico', onIsValidFail: 'El correo es inválido' }}
            required
            textTransform='lowercase' />
        </div>
      </div>
    </div>
  );
}

export default Step6;