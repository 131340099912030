import './styles/guest-item.css';
import { useContext, useEffect, useState } from 'react';
import { globalContext } from '../context/GlobalContext.js';
import Button from './Button';
import FileChooser from '../objects/FileChooser';
import LoadingBlock from './LoadingBlock';
import DAOServ from '../objects/DAOServ.js';
import ErrHandler from '../objects/ErrHandler';
import { DefUsPic } from '../assets/images/index';
import GenericFile from '../objects/GenericFile.js';

/** Renders a GuestItem compo. The guest's picture will be fetched if unset.
 * @typedef {import('../objects/User.js').default} User
 * @param {Object} props The props object.
 * @param {User} props.guest An User instance. Its username will be used as an
 * id.
 * @param {boolean} props.pictureToGrayscale If true, user's picture will be rendered
 * grayscale.
 * @param {string} props.userIcon Renders an icon at the left of the username.
 * @param {Object} [props.btnProp] Button properties to render a button
 * (set to undefined to skip button render).
 * @param {string} [props.btnProp.className] Button class name.
 * @param {string} props.btnProp.icon Button icon (if undefined, button
 * will be not rendered).
 * @param {string} [props.btnProp.id] Button id.
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.btnProp.onClick] Button
 * onClick function handler
 * @param {string} [props.btnProp.title] Button title (on hover).
 */
const GuestItem = props => {
  // *** useContext ***
  const { getCacheFile, pushCacheFile } = useContext(globalContext);
  // *** useState ***
  const [picture, setPicture] = useState(props.guest.getPicture()?.getURLData());

  const getBtnClassName = () => {
    if (props.btnProp?.className)
      return ` ${props.btnProp.className}`;
    else return '';
  }

  const getPicClassName = () => {
    return props.pictureToGrayscale ? 'grayscale' : '';
  }

  useEffect(() => {
    const fetchPicture = async () => {
      if (!props.guest) return;

      let newPicture = DefUsPic;

      try {
        const auxData = await DAOServ.post('get_user_public_data', { data: props.guest.getUsername() }, 'JSON');

        if (auxData['picture']) {
          // Checking if file is cached.
          const auxCF = getCacheFile(auxData['picture']);

          if (auxCF !== undefined) {  // File store in cache.
            newPicture = typeof auxCF.content === 'string'
              ? auxCF.content
              : await FileChooser.readAsDataURL(auxCF.content);
          } else { // Retrieve file from server.
            const blob = await DAOServ.getFile(auxData['picture']);
            newPicture = await FileChooser.readAsDataURL(blob);
            pushCacheFile({ content: newPicture, name: auxData['picture'], size: blob.size });
          }

          props.guest.setPicture(new GenericFile({ urlData: newPicture }));
        }
      } catch (err) {
        if (err) ErrHandler.parseError(err);
      } finally {
        setPicture(newPicture);
      }
    }

    if (!picture) fetchPicture();
  }, [getCacheFile, pushCacheFile, picture, props.guest])

  return (
    <div className="guest-item" id={props.guest.getUsername()}>
      <div className="img-container">
        {!picture && <LoadingBlock miniFigure />}
        {picture && <img src={picture} className={getPicClassName()} alt={`${props.guest.getUsername()}-cover`} />}
      </div>
      {props.userIcon && <img className="user-icon" src={props.userIcon} alt="banned" />}
      <div className="info-container">
        <h4 className="overset username">@{props.guest.getUsername()}</h4>
      </div>
      {props.btnProp?.icon && <div className="button-container">
        <Button
          className={`empty borderless rounded${getBtnClassName()}`}
          icon={props.btnProp.icon}
          onClick={props.btnProp.onClick}
          title={props.btnProp.title}
        />
      </div>}
    </div>
  );
}

export default GuestItem;