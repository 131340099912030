import './styles/user-card.css';
// Icons
import { DefUsPic } from '../assets/images';
// React.
import { useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/GlobalContext.js';
// My compos and objects.
import ErrHandler from '../objects/ErrHandler';
import FileChooser from '../objects/FileChooser';
import DAOServ from '../objects/DAOServ.js';
import LoadingBlock from './LoadingBlock';
import Global from '../objects/Global';
import GenericFile from '../objects/GenericFile.js';

/** Renders a UserPill compo.
 * @typedef {import('../objects/User.js').default} User;
 * @param {Object} props The props object.
 * @param {boolean} [props.animFig] An animated figure that will replace icon.
 * @param {*} [props.defaultPicture] The picture to be displayed instead of the user's
 * picture. If undefined the user's picture will be displayed (if not found, compo will
 * try to fetch it).
 * @param {string} [props.displayName] The name that will be displayed instead of the
 * user's username. If undefined, the username will take its place.
 * @param {boolean} [props.fullWidth] If true, card will render its width to its parent's
 * @param {boolean} [props.hover] If true, card will react on mouse hover. No need to be
 * defined if onClick callback is setted. However, if onClick is setted but hover is false, this
 * option is omited.
 * @param {*} [props.icon] An icon to be displayed between user picture and username.
 * @param {'complete'|'error'|'warning'} [props.iconFilter] A color filter for the icon. May not
 * work properly when the icon color is not #9337EF (purple).
 * @param {string} props.id The id compo.
 * @param {boolean} [props.mini] Set this to true to reder the compo in miniature.
 * @param {() => void} [props.onClick] Add onClick event
 * listener.
 * @param {boolean} [props.rounded] If true, card's borders will be rounded.
 * @param {'glass'|'gold'|'self'|'complete'|'error'|'warning'} [props.type]
 * Changes the background color.
 * @param {User} props.user The user object to be rendered. If you want to fetch its picture, its id or
 * username must be defined.
 */
const UserCard = props => {
  // *** useContext ***
  const { getCacheFile, pushCacheFile } = useContext(globalContext);
  // *** useRef ***
  const animFig = useRef(Global.getRandom(['f1', 'f2', 'f3']));
  // *** useState ***
  const [picture, setPicture] = useState(props.defaultPicture || props.user.getPicture()?.getURLData());
  const getClassName = () => {
    let className = '';

    if (props.fullWidth) className = `${className} full-width`;
    if (props.rounded) className = `${className} rounded`;
    if ((props.onClick || props.hover) && (props.hover || props.hover === undefined))
      className = `${className} hover`;
    if (props.mini) className = `${className} mini`;
    if (props.type) className = `${className} ${props.type}`;

    return className;
  }

  const getIconClassName = () => {
    return `icon${props.iconFilter ? ` ${props.iconFilter}` : ''}`;
  }

  useEffect(() => {
    const fetchPicture = async () => {
      let newPicture = DefUsPic;

      try {
        let func = 'get_user_public_data', param = props.user.getId();

        if (props.user.getUsername()) param = props.user.getUsername();

        const picture = props.user.getPicture()?.getName()
          || (await DAOServ.post(func, { data: param }, 'JSON'))['picture'];

        if (Boolean(picture)) {
          // Checking cached files.
          const auxCF = getCacheFile(picture);

          if (auxCF !== undefined) { // Fetch file from cached files.
            newPicture = typeof auxCF.content === 'string'
              ? auxCF.content
              : await FileChooser.readAsDataURL(auxCF.content);

            if (props.user.getPicture() === undefined) {
              props.user.setPicture(new GenericFile({
                name: auxCF.name,
                size: auxCF.size,
                urlData: newPicture
              }));
            } else {
              props.user.getPicture().setURLData(newPicture);
              props.user.getPicture().setSize(auxCF.size);
            }
          } else { // Fetch file from server.
            const blob = await DAOServ.getFile(picture);
            newPicture = await FileChooser.readAsDataURL(blob);

            if (props.user.getPicture() === undefined) {
              props.user.setPicture(new GenericFile({
                name: picture,
                size: blob.size,
                urlData: newPicture
              }));
            } else {
              props.user.getPicture().setURLData(newPicture);
              props.user.getPicture().setSize(blob.size);
            }
          }
        } else throw new Error(ErrHandler.getError(ErrHandler.CODES.NOT_FOUND));

        await DAOServ.post(func, {data: param}, 'JSON')
          .then(data => {
            if (data.picture) return DAOServ.getFile(data.picture);
            else return Promise.reject();
          }).then(blob => FileChooser.readAsDataURL(blob))
          .then(urlData => {
            props.user.getPicture().setURLData(urlData);
            setPicture(urlData);
          });
      } catch (err) {
        if (err && ErrHandler.getCode(err) !== ErrHandler.CODES.NOT_FOUND)
          ErrHandler.parseError(err);
      } finally {
        setPicture(newPicture);
      }
    }

    if (!props.defaultPicture) fetchPicture();
    else setPicture(props.defaultPicture || DefUsPic);
  }, [props.defaultPicture, props.user, getCacheFile, pushCacheFile]);

  return (
    <div className={`user-card${getClassName()}`} id={props.id} onClick={props.onClick}>
      <div className="usr-pic-container">
        {!picture && <LoadingBlock position='relative' miniFigure />}
        {picture && <img src={picture} alt="" />}
      </div>
      {props.animFig && <span className={`animated-figure mini ${animFig.current}`} />}
      {!props.animFig && props.icon && <img src={props.icon} alt="assigned" className={getIconClassName()} />}
      <h5 className={props.icon ? 'reduced' : undefined}>
        {props.displayName ? props.displayName : `@${props.user.getUsername()}`}
      </h5>
    </div>
  );
}

export default UserCard;