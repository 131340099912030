import '../styles/profilemenu.css';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef } from 'react';
import { globalContext } from '../context/GlobalContext';
import Button from '../components/Button';
import Global from '../objects/Global';
import UIRender from '../objects/UIRender';
import DAOServ from '../objects/DAOServ';
import { LoginIcon, LogoutIcon, HomeIcon, SubsIcon, UserIcon } from '../assets/images';
import ErrHandler from '../objects/ErrHandler';

const ProfileMenu = () => {
  // *** useContext ***
  const {
    currSession,
    currSessionPicture,
    pushMessageHint,
    setShowLoadingScreen,
    setShowLogin,
    setShowProfileMenu,
  } = useContext(globalContext);
  // *** useNavigate ***
  const navigate = useNavigate();
  // *** useRef ***
  const compoId = useRef("profile-menu");
  const popup = useRef(/** @type {HTMLDivElement} */(undefined));

  /** @type {React.AnimationEventHandler} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      setShowProfileMenu(false);
  }

  const popupClickHandler = async e => {
    if (Global.findParent(e.target, { className: 'button', maxChecks: 2 })) {
      if (e.target.id.includes('login')) { // Login button pushed.
        setShowLogin(true);
      } else if (e.target.id.includes('logout')) { // Reques logout and deletes local storage.
        setShowLoadingScreen(true);

        await DAOServ.post('logout', { tst: currSession.tst }, 'JSON')
          .catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));

        localStorage.removeItem('tst');
        UIRender.reloadPage();
      } else { // Pushed any other button.
        let path;

        if (e.target.id.includes('profile')) {
          path = Global.PATH_ACCOUNT;
        } else if (e.target.id.includes('subscriptions')) {
          path = Global.PATH_LICENSE_MANAGEMENT;
        } else if (e.target.id.includes('folder')) {
          path = Global.PATH_HOME;
        } else if (e.target.id.includes('users-manager')) {
          path = Global.PATH_USERS_MANAGEMENT;
        }

        if (path && !Global.comparePathname(path)) navigate(path);
      }

      UIRender.hideElement(popup.current); // Hidding profile menu.
    }
  }

  useEffect(() => {
    const documentHandleOnClick = e => {
      // Clicked outside of the component.
      if (popup.current !== undefined && !popup.current.contains(e.target))
        UIRender.hideElement(popup.current);
    }

    document.addEventListener('click', documentHandleOnClick, true);

    return () => document.removeEventListener('click', documentHandleOnClick, true);
  }, []);

  return (
    <nav className="profile-menu" id={compoId.current} ref={popup} onAnimationEnd={dispose} onClick={popupClickHandler} >
      <div className="user-info">
        <img src={currSessionPicture} alt="profile-img" className="profile-img" />
        <div className="text">
          <p>{currSession.sessionStatus === 1 ? 'Hola,' : 'Racchome'}</p>
          {currSession.sessionStatus === 1 && <h4>@{currSession.username}</h4>}
        </div>
      </div>
      {currSession.sessionStatus !== 1 &&
        <div className="not-logged">
          <Button
            id='login'
            className='empty full-width animate'
            value='Iniciar sesión'
            icon={LoginIcon} />
        </div>}
      {currSession.sessionStatus === 1 &&
        <div className="logged">
          <div>
            <Button
              className='empty animate'
              disabled={Global.comparePathname(Global.PATH_ACCOUNT)}
              id='profile'
              icon={UserIcon}
              value='Mi perfil' />
            <Button
              className='empty animate'
              disabled={Global.comparePathname(Global.PATH_LICENSE_MANAGEMENT)}
              id='subscriptions'
              icon={SubsIcon}
              value='Suscripciones' />
          </div>
          <Button
            className='empty full-width animate'
            disabled={Global.comparePathname(Global.PATH_HOME)}
            id='folder'
            icon={HomeIcon}
            value='Inicio' />
          <Button
            className='error empty full-width animate'
            id='logout'
            icon={LogoutIcon}
            value='Cerrar sesión' />
        </div>}
    </nav>
  );
}

export default ProfileMenu;
