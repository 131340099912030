import '../styles/pass-tool-wizard.css';
import { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from '../context/GlobalContext';
import Button from '../components/Button';
import ErrHandler from '../objects/ErrHandler';
import Pass from '../objects/Pass';
import PetAssistant from '../components/PetAssistant';
import DAOServ from '../objects/DAOServ';
import Switch from '../components/Switch';
import UIRender from "../objects/UIRender";
import { CloseIcon, DefUsPic, SaveIcon } from '../assets/images';
import Selectbar from '../components/Selectbar';
import User from '../objects/User';

/** Renders a PassToolWizard popup compo.
 * @param {Object} props The props object.
 * @param {() => void} props.onHide A callback function that will be called when
 * compo is hiden.
 * @param {() => void} [props.onResolve] A callback function that will be called
 * when compo resolves pass changes.
 * @param {User[]} props.users The list of existing users.
 */
const PassToolWizard = props => {
  // *** useContext ***
  const { currSession, pushMessageHint } = useContext(globalContext);
  // *** useRef ***
  const compoId = useRef('pass-tool-wizard');
  const pass = useRef(new Pass());
  const popup = useRef();
  // *** useState ***
  const [applyPass, setApplyPass] = useState(false);
  const [applyTo, setApplyTo] = useState(/** @type {number[]|-1} */([]))
  const [disableUI, setDisableUI] = useState();
  const [status, setStatus] = useState(/** @type {number} */(undefined));

  /** @type {React.AnimationEventHandler} */
  const dispose = e => {
    if (e.target === popup.current && UIRender.isHidden(popup.current))
      props.onHide();
  }

  const formHandleOnSubmit = async () => {
    setDisableUI(true);

    const payload = {
      tst: currSession.tst,
      users: applyTo,
      pass: applyPass ? pass.current.getAllPasses() : undefined,
      status
    }

    await DAOServ.post('pass_tool_wizard', payload, 'JSON')
      .then(() => {
        const usersApply = applyTo === -1
          ? props.users
          : props.users.filter(u => applyTo.find(aU => aU === u.getId()));

        usersApply.forEach(uA => {
          uA.setStatus(status);
          if (applyPass) uA.setPass(new Pass(pass.current));
        });

        pushMessageHint({ message: 'Usuarios actualizados', type: 'complete' });
        UIRender.hideElement(popup.current);

        if (props.onResolve) props.onResolve();
      }).catch(err => pushMessageHint({ message: ErrHandler.parseError(err), type: 'error' }));

    setDisableUI(false);
  }

  const renderUsers = () => {
    return props.users.map(user => {
      return (<div className="flex-box user-list-item" key={user.getUsername()}>
        <div className="child auto-width m3">
          <img src={user.getPicture().getURLData() || DefUsPic} alt={user.getPicture().getName()} />
        </div>
        <div className="child m3 jc-left">
          <h4 className="overset">{user.getUsername()}</h4>
        </div>
        <div className="child auto-width">
          <Switch disabled={applyTo === -1 || disableUI}
            forceChangeValue={applyTo === -1}
            action={async state => {
              if (state) { // Add to applyTo array.
                applyTo.push(user.getId());

                setApplyTo(applyTo.length === props.users.length ? -1 : [...applyTo]);
              } else { // Remove from applyTo array.
                applyTo.splice(applyTo.findIndex(id => id === user.getId()), 1);
                setApplyTo([...applyTo]);
              }
            }}
            placeholder={{ default: '' }} />
        </div>
      </div>);
    })
  }

  useEffect(() => {
    const id = compoId.current;
    const options = { footer: true, navbar: true };
    const parent = popup.current?.parentNode;

    UIRender.disableGlobalScroll(id);
    UIRender.disableSiblings(popup.current, options); // Disable siblings.

    return () => {
      UIRender.enableGlobalScroll(id);
      UIRender.enableChilds(parent, options, id);
    }
  }, []);

  return (
    <div ref={popup} className={'popup-wrapper pass-tool-wizard'} id={compoId.current} onAnimationEnd={dispose}>
      <div className="popup">
        <div className="top-bar">
          <h2 className="title highlight">Permisos globales</h2>
          <Button disabled={disableUI}
            empty
            rounded
            reduced
            borderless
            icon={CloseIcon}
            onClick={() => UIRender.hideElement(popup.current)}
            typeRender='error' />
        </div>
        <PetAssistant pet='aaron'
          message={'Selecciona los permisos que quieres asignar globalmente y a los usuarios'
            + ' que serán afectados.'} />
        <div className="popup-content">
          <Selectbar disabled={disableUI}
            onChange={option => setStatus(option)}
            options={[
              { displayValue: 'Activa', value: User.STATUS_ACT },
              { displayValue: 'Inactiva', value: User.STATUS_INA }
            ]}
            placeholder='Estado de la cuenta'
            undefinedOption='No cambiar' />
          <div className="box shadowless">
            <Switch disabled={disableUI}
              action={async state => {
                setApplyPass(state);
                return Promise.resolve();
              }} placeholder={{ default: 'Aplicar permisos' }} />
            <div className="flex-box wrap">
              <div className="box borderless">
                <h5 className="overset">Cuenta</h5>
                <div className="flex-box dir-column m3">
                  <div className="child jc-left">
                    <Switch disabled={disableUI || !applyPass}
                      action={async state => {
                        pass.current.setPass(Pass.UP_SINF, state);
                        return Promise.resolve();
                      }} placeholder={{ default: 'Actualizar información personal' }} />
                  </div>
                  <div className="child jc-left">
                    <Switch disabled={disableUI || !applyPass}
                      action={async state => {
                        pass.current.setPass(Pass.UP_PICT, state);
                        return Promise.resolve();
                      }} placeholder={{ default: 'Actualizar foto de perfil' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-box wrap">
              <div className="box borderless">
                <h5 className="overset">Propiedades</h5>
                <div className="flex-box dir-column m3">
                  <div className="child jc-left">
                    <Switch disabled={disableUI || !applyPass}
                      action={async state => {
                        pass.current.setPass(Pass.IN_ESTA, state);
                        return Promise.resolve();
                      }} placeholder={{ default: 'Crear propiedades' }} />
                  </div>
                  <div className="child jc-left">
                    <Switch disabled={disableUI || !applyPass}
                      action={async state => {
                        pass.current.setPass(Pass.UP_ESTA, state);
                        return Promise.resolve();
                      }} placeholder={{ default: 'Actualizar propiedades' }} />
                  </div>
                  <div className="child jc-left">
                    <Switch disabled={disableUI || !applyPass}
                      action={async state => {
                        pass.current.setPass(Pass.DE_ESTA, state);
                        return Promise.resolve();
                      }} placeholder={{ default: 'Eliminar propiedades' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-box wrap">
              <div className="child">
                <div className="box borderless">
                  <h5 className="overset">Contratos</h5>
                  <div className="flex-box dir-column m3">
                    <div className="child jc-left">
                      <Switch disabled={disableUI || !applyPass}
                        action={async state => {
                          pass.current.setPass(Pass.IN_CONT, state);
                          return Promise.resolve();
                        }} placeholder={{ default: 'Crear contratos' }} />
                    </div>
                    <div className="child jc-left">
                      <Switch disabled={disableUI || !applyPass}
                        action={async state => {
                          pass.current.setPass(Pass.DE_CONT, state);
                          return Promise.resolve();
                        }} placeholder={{ default: 'Cancelar contratos' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="child">
                <div className="box borderless">
                  <h5 className="overset">Subastas</h5>
                  <div className="flex-box dir-column m3">
                    <div className="child jc-left">
                      <Switch disabled={disableUI || !applyPass}
                        action={async state => {
                          pass.current.setPass(Pass.IN_BIDD, state);
                          return Promise.resolve();
                        }} placeholder={{ default: 'Iniciar subastas' }} />
                    </div>
                    <div className="child jc-left">
                      <Switch disabled={disableUI || !applyPass}
                        action={async state => {
                          pass.current.setPass(Pass.DE_BIDD, state);
                          return Promise.resolve();
                        }} placeholder={{ default: 'Finalizar subastas' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box shadowless">
              <div className="flex-box m3">
                <div className="child jc-left">
                  <h5 className="highlight">Aplicar a...</h5>
                </div>
                <div className="child auto-width">
                  <Switch disabled={disableUI}
                    forceChangeValue={applyTo === -1}
                    action={async state => {
                      setApplyTo(state ? -1 : []);
                      return Promise.resolve();
                    }} placeholder={{ default: 'Todos' }} />
                </div>
              </div>
              <div className="users-list">
                {renderUsers()}
              </div>
            </div>
          </div>
        </div>
        <Button disabled={(applyTo !== -1 && applyTo.length === 0) || (!applyPass && status === undefined)}
          icon={SaveIcon}
          isWaiting={disableUI}
          onClick={formHandleOnSubmit}
          value='Aplicar' />
      </div>
    </div>
  );
}

export default PassToolWizard;